//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_Main.js';

//----------------------------------------------------------------------------------------

export const App_Main = ({ Props }) => {

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//████████████████████████████████████████████████████████████████████████████████████

return (html`
<${Styled}>

  <${RouterDOM.Switch}>

    <${RouterDOM.Route} exact path=${["/", "/Aura", "/aura"]}>
      aura
    <//>

  <//>

<//>
`);

};