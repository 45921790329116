//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_Menu.js';

//----------------------------------------------------------------------------------------
  export const App_Menu = ({ Props }) => {
//----------------------------------------------------------------------------------------

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  let URI_SRC = `${Public.ENV.URL}/Apps/Sidebar/Menu/Media`;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Functions
//----------------------------------------------------------------------------------------

  const changeMenu = (name) => {
    
    Props.Section.set(name);
    Props.Modal.set({
      type: "",
      options: {}
    });
    Public.Scripts.Audio.Sound({ID: 'Click'});

  };

  function createHalo(imageElement) {
    const halo = document.createElement('img');
    halo.classList.add('_Halo')
    halo.src = imageElement.src;
    halo.style.position = 'absolute';
    halo.style.top = '50%';
    halo.style.left = '50%';
    halo.style.transform = 'translate(-50%, -50%)';
    halo.style.width = `${imageElement.offsetWidth * 1.1}px`;
    halo.style.height = `${imageElement.offsetHeight * 1.1}px`;
    halo.style.filter = 'blur(10px)';
    // halo.style.zIndex = '-1';
    halo.style.pointerEvents = 'none';
    imageElement.parentNode.style.position = 'relative';
    imageElement.parentNode.appendChild(halo);
  }

  function removeHalo(halo) {
    if (halo) {
      halo.remove(); // Quitar el halo del DOM
    }
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

function RenderSidebar () {

  return (html`

  <${RouterDOM.Link} to="/aura" key="Menu_Aura">
  <li onClick=${()=>{changeMenu("aura");}}
    className="${location.pathname === `/` || location.pathname.startsWith(`/aura`) ? "Active" : ""}">
    <img src="${URI_SRC}/Aura.png" className="_Icon"/>
  </li>
  <//>

  <${RouterDOM.Link} to="/intro" key="Menu_Manual">
  <li onClick=${()=>{ changeMenu("intro");}}
    className="
       ${location.pathname.startsWith(`/intro`) 
    || location.pathname.startsWith(`/Items/Chips/1`) 
    || location.pathname.startsWith(`/roadmap`) 
    || location.pathname.startsWith(`/about`) 
    ? "Active" : ""}">
    <img src="${URI_SRC}/Manual.png" className="_Icon"/>
  </li>
  <//>

  <${RouterDOM.Link} to="/maia" key="Menu_Maia">
  <li 
    onMouseEnter=${(e) => createHalo(e.currentTarget.querySelector("._Icon"))}
    onMouseLeave=${(e) => removeHalo(e.currentTarget.querySelector("._Halo"))}
    onClick=${() => changeMenu("maia")}
    className=${`${location.pathname.startsWith(`/maia`) ? "Active" : ""}`}>
    <img src=${`${URI_SRC}/Chip.png`} className="_Icon" id="halo-image"/>
  </li>
  <//>

  <${RouterDOM.Link} to="/user" key="Menu_User">
  <li onClick=${()=>{changeMenu("user");}}
  className="${location.pathname.startsWith(`/user`) ? "Active" : ""}">
    <img src="${URI_SRC}/User.png" className="_Icon"/>
  </li>
  <//>

  ${Public.TOKEN.PRIVILEGE === 'ADMIN' && html`

  <${RouterDOM.Link} to="/digitori" key="Menu_Egg">
  <li onClick=${()=>{changeMenu("digitori");}}
    className="${location.pathname === `/` || location.pathname.startsWith(`/digitori`) ? "Active" : ""}">
    <img src="${URI_SRC}/Digitori.png" className="_Icon"/>
  </li>
  <//>

  <${RouterDOM.Link} to="/admin/calendar" key="Menu_Admin">
  <li onClick=${()=>{changeMenu("admin");}}
  className="${location.pathname.startsWith(`/admin`) ? "Active" : ""}">
    <img src="${URI_SRC}/Admin.png" className="_Icon"/>
  </li>
  <//>

  `}
  
  `);

}

//----------------------------------------------------------------------------------------

return (html`
<${Styled}>
  ${RenderSidebar()}
<//>
`);

//----------------------------------------------------------------------------------------
}

/*
<${RouterDOM.Link} to="/items/chips" key="Menu_Items">
<li onClick=${()=>{changeMenu("items/chips");}}
  className="${location.pathname.startsWith(`/items/chips`) ? "Active" : ""}">
  <img src="${URI_SRC}/Chip.png" className="_Icon"/>
</li>
<//>
*/