//----------------------------------------------------------------------------------------

  import { App_Main } from "./Main/App_Main.js";
  import { App_Sidebar } from "./Sidebar/App_Sidebar.js";

//----------------------------------------------------------------------------------------
  export default ({ }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------
 
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const RouterDOM = Public.Libs.RouterDOM;
  // const { useTranslation, Trans } = Public.i18n.React;
  
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Props
//----------------------------------------------------------------------------------------

  const Props = {
   
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${RouterDOM.Router}>

<div className="Board">
  
  <${App_Sidebar} Props=${Props} />
  <${App_Main} Props=${Props} />

</div>

<//>
`);

//----------------------------------------------------------------------------------------
};