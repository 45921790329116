/*▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██████████████████████████████████   📁 imports   ████████████████████████████████████
██████████████████████████████████████████████████████████████████████████████████████
██*/                                                                              /*██
██*/  /*-----------------------------------------------------------------------   /*██
██/*  |  📁 Template                                                          |   /*██
██*/  /*---------------------------------------------------------------------*/   /*██
██*/  import { Styled_Maia } from './Style/Styled_Maia.js';                       /*██
██*/                                                                              /*██
██*/  /*-----------------------------------------------------------------------   /*██
██/*  |  📁 App                                                               |   /*██
██*/  /*---------------------------------------------------------------------*/   /*██
██*/  import { MAIA } from './Core/MAIA.js';                                      /*██
██*/                                                                              /*██
████████████████████████████████████████████████████████████████████████████████████*/

export const App_MAIA = ({ Props }) => { 
  
  const { useRef, useEffect, useLayoutEffect } = Public.Libs.React;
  const html = Public.Libs.html;

  // DOM references.
  const theCase = useRef(null);
  const theRender = useRef(null);
  const theCanvas = useRef(null);
  const thePanel = useRef(null);
  const theLog = useRef(null);

  let _MAIA;

  const Load_Props = (props) => {
    return {
      ENV: {
        User: props.ENV.User,
        Chip: props.ENV.Chip,
        Scene: props.ENV.Scene,
        Mode: props.ENV.Mode,
      },

      Tree: {
        Client: {
          Agent: undefined,
          Window: window,
          Style: props.Tree.Client.Style,
          DOM: {
            Case: theCase.current,
            Render: theRender.current,
            Canvas: theCanvas.current,
            Panel: thePanel.current,
            Log: theLog.current
          },
        },
        Render: props.Tree.Render
      },

      Reload: () => {
        // window.location.reload(false); 
        // document.body.requestFullscreen();
        // _MAIA = new MAIA(Agent, Options);
      }

    }
  }

  // React en StrictMode desmonta/remonta componentes en desarrollo, por lo que necesitamos:
  // 1) Comprobar theCanvas.current antes y después del await para evitar referencias nulas
  // 2) Un flag isComponentMounted para evitar inicializar cuando el componente ya no existe
  useEffect(() => {

    let isComponentMounted = true;  
   
    const init = async () => {
      if(!theCanvas.current) return;
      await new Promise(resolve => setTimeout(resolve, 10));
      if (!isComponentMounted || !theCanvas.current) return;

      let Maia_Props = Load_Props(Props);
      _MAIA = new MAIA(Maia_Props);
      console.log("Init MAIA");
    };
   
    init();
   
    return () => {
      isComponentMounted = false;
      if (_MAIA) {
        console.log("❌ Unmounted MAIA");
        _MAIA.TREE.SOUND.Reset();
        _MAIA.Loaded = false;
        _MAIA = null;
      }
    };

   }, [theCanvas]);

/*▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██████████████████████████████████   🖼️ Render   █████████████████████████████████████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀*/
    
  return (html`
  <${Styled_Maia} Style=${Props.Tree?.Client?.Style}>
  <div id="Maia_Case" ref=${theCase}>
  <div id="Logo">
    <!--<img src="${Public.ENV.URL}/Public/Media/Symbol/Logo/Kanji.png"/>-->
    <!--<i className="devicon-raspberrypi-line"></i>-->
    <!--<img src="${Public.ENV.URL}/Public/Media/Images/Logo/Maia.png" />-->
    <span>MAIA</span>
  </div>
  <div id="Maia_Screen">
    <div id="Maia_Render" ref=${theRender}>
      <div id="Maia_Log" ref=${theLog}></div>
      <canvas id="Maia_Canvas" ref=${theCanvas}></canvas>
      <div id="Maia_Panel" ref=${thePanel} className="focus"></div>
    </div>
  </div>
  </div>
  <//>
  `);
  
  };