import { Chip_Landing } from './Part/Landing/Chip_Landing.js';
import { App_About } from '../../../About/App_About.js';
import { Style } from './Style/Style_Items_Chips_Detail.js';

export const Items_Chips_Detail = ({ Props }) => {

  const { useState, useEffect, useRef, Suspense, memo } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const {chip_id} = RouterDOM.useParams();
  const history = RouterDOM.useHistory();
  const Part_Parallax = Public.Scripts.Choose_Part('Parallax');  
  let URI = `${Public.ENV.URI}/Items/Chips/Detail`;

//████████████████████████████████████████████████████████████████████████████████████

  const [Item, SetItem] = useState(null);
  const [Images, SetImages] = useState([
    {URL: `${URI}/Media/Parallax/0.png`},
    {URL: `${URI}/Media/Parallax/1.png`},
    {URL: `${URI}/Media/Parallax/2.png`}
  ]);

  useEffect(() => {
    Get_Chip();
  }, []);

  const Get_Chip = async () => {
    Public.DB.Read({
      URI: `Items/Chips`, 
      Interface: { ID: true, Title: true, Tags: true, Description: true, Files: false },
      Items: [chip_id], 
      Callback: (items) => {  
        SetItem(items[0]);
      }
    });
  }

  const Open_Game = () => {
    Public.Scripts.Audio.Sound({ID: 'Pop_Open'});
    Public.Scripts.Notify.Send({
      Title: "Demo", Description: `¡Iniciando!`, Image: "Gamepad", Time: 5000
    });
  }

  const Open_Trailer = () => {
    Public.Scripts.Audio.Sound({ID: 'Pop_Open'});
    Public.Scripts.Notify.Send({
      Title: "Próximamente", Description: `¡Disponible próximamente!`, Image: "Video", Time: 5000
    });
  }

//████████████████████████████████████████████████████████████████████████████████████

return html`
<${Styled}>

  ${Item && html`
    <${Chip_Landing} 
      Item=${Item}
      Images=${Images}
      Open_Game=${Open_Game}
      Open_Trailer=${Open_Trailer}
      Part_Parallax=${Part_Parallax}
      RouterDOM=${RouterDOM}
      html=${html}
      Suspense=${Suspense}
    />
  `}

  <div className="_Main">
    <${App_About} Props=${Props}/>
  </div>
<//>
`;
}