
import { Styled } from './Style/Style_App_Readme.js';

//----------------------------------------------------------------------------------------

export const App_Readme = ({ Props }) => {

let URI = `${Public.ENV.URL}/Apps/Core/Intro/Intro`;
const { useState, useEffect, useRef } = Public.Libs.React;
const html = Public.Libs.html;
const Style = Styled(Public.Libs.styled, {}, URI);
const RouterDOM = Public.Libs.RouterDOM;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------


//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Render_Back = () => {
    return html`
    <ul id="CloudTags">
    <${RouterDOM.Link} to="/Intro">
    <li data-color="#777777" onClick=${(e)=>{clickTag(e)}}>
      <i className="icofont-spinner-alt-4 icofont-1x"></i>Back
    </li>
    <//>
    </ul>
    `;
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Style}>
<section>


${Render_Back()}

Readme...


</section>
<//>
`);

};