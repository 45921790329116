//----------------------------------------------------------------------------------------

import { Style } from './Style/Style_App_About_Detail.js';

//----------------------------------------------------------------------------------------

export const App_About_Detail = ({ Props }) => {

  const { useState, useEffect } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const [Html, SetHtml] = useState(null);
  const [Item, SetItem] = useState(null);
  const [Page, SetPage] = useState(null);

  useEffect(() => {
    Read_About();
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Read_About = () => {
    Public.DB.Read({
      URI: `Admin/About`, 
      Interface: {"Files": true},
      Items: [id],
      Callback: (Items) => { 
        console.log(Items[0]);
        SetItem(Items[0]);
        SetPage(Items[0].Files[0]);
      }
    });
  }

  useEffect(() => { if (!Page) { return; }

    const loadBlobContent = async () => {
      try {
        const text = await Page.Blob.text();
        console.log(text)
        SetHtml(text);
      }
      catch (err) {
        console.error('Error al cargar el contenido del Blob:', err);
        SetHtml('<p>Error: No se pudo cargar el contenido de la página</p>');
      }
    };

    loadBlobContent();

  }, [Page]);

  function processImagesInPage() {
    const pageNode = document.getElementById('_Page');
    if (!pageNode) {
      console.warn('No se encontró el elemento con ID "_Page"');
      return;
    }
  
    const parser = new DOMParser();
    const doc = parser.parseFromString(pageNode.innerHTML, 'text/html');
    const images = doc.querySelectorAll('img');
  
    console.log(pageNode)
    images.forEach((img, index) => {
      
      if (index < Item.Files.length) {
        const file = Item.Files[index];
        console.info(`src:`, Item.Files, index);
        if (file && file.Blob) {
          console.info(`index:`, index);
          try {
            const imgURL = Public.Scripts.Blob_To_Img_URL(file.Blob);
            img.setAttribute('src', imgURL);
          } catch (error) {
            console.error(`Error al procesar la imagen ${index}:`, error);
          }
        } else {
          console.warn(`No se encontró Blob para la imagen ${index}`);
        }
      }
    });
  
    pageNode.innerHTML = doc.body.innerHTML;
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

const Render = () => {

  if (!Item) { return null; }

  return filesContent.map((file, index) => {
    if (file.type === 'html') {
      return html`
        <div key=${index} className="_Text" dangerouslySetInnerHTML=${{ __html: file.content }}></div>
      `;
    } else if (file.type === 'png') {
      return html`
        <div key=${index} className="_Image">
          <img src=${file.content} className="icon"/>
        </div>
      `;
    }
  });

}

return (html`
  <${Styled}>
    <div className="App_About_Detail">
      <div className="Navbar">
        <h2 className="_Title">About</h2>
        <div className="_Info"><i className="icofont-info-square icofont-1x"/></div>
      </div>
      <div className="_Main">
        <div id="_Page" className="_Container">
                   
        </div>
      </div>
    </div>
  <//>
`);

};