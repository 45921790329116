
  import { Styles } from './Styles/Styles_Landing.js';

//----------------------------------------------------------------------------------------
  export default ({ }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------
 
  let URI_Media = `${Public.ENV.URL}/Apps/Landing/Media`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Styles, {URI_Media});
  const RouterDOM = Public.Libs.RouterDOM;
  const location = RouterDOM.useLocation();
  const history = RouterDOM.useHistory();

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => {

  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled}>

<div id="Landing">

<div className="_Intro">
  
  <div className="_Navbar">
    <h1 className="_Logotipo ${Public.TOKEN.PRIVILEGE  === 'USER' || Public.TOKEN.PRIVILEGE === 'ADMIN' && 'Active'}">
    <${RouterDOM.Link} to=${'/init'}>
      <img src="${Public.ENV.URL}/Public/Media/Icons/Favicon/Initori.png"/>
      <span>initori</span>
    <//>
    </h1>
  </div>


</div>

<div className="_Manual">
  
</div>

<div className="_Trailer">
  <video controls>
  <source src="${Public.ENV.URL}/Apps/Landing/Media/Trailer/Trailer.mp4" type="video/mp4"/>
  </video>
</div>

<div className="_Social">
  
</div>

</div><!--App-->

<//>
`);

};