export const Styles = (styled, Proto, Props) => {

console.log(Proto, Props)

return styled.div`
width: 100%;
height: 100%;
padding: 0;
margin: 0;
background: #eee;

#Landing {
width: 100%;
height: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 0;

  ._Intro {
  width: 100%;
  height: 100%;
  height: 100vh;
  background: #eee;
  background-image: url("${Props.URI_Media}/Poster.png");
  background-size: cover;
  background-position: 20% 30%;

    ._Navbar {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    backdrop-filter: saturate(80%) blur(10px);
    background: #eee;

    padding: 2rem 6rem;

      ._Logotipo {
      color: #333;
      font-family: "Ginora Sans";
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;

        a {
        color: #333;
        font-size: 2.8rem;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;    
        }

        img {
        width: 4.8rem;
        margin-left: -0.5rem;
        margin-right: 0.5rem;
        margin-top: -0.5rem;
        }

        span {
          @media (max-width: 768px) {
          font-size: 2rem;
          }
        }

        &.Active {
          img:hover {
          transform: rotate(1440deg);
          transition: 1.7s;
          }
        }

      } 

    }
    
  }

  ._Manual {
  width: 100%;
  height: 100%;
  height: 100vh;
  background: beige;
  }

  ._Trailer {
  width: 100%;
  height: 100%;
  height: 100vh;
  background: black;
    
    video {
    width: 100%;
    height: 100%;
    }

  }

  ._Social {
  width: 100%;
  height: 100%;
  height: 100vh;
  background: pink;
  }

}

`;

}