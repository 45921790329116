//----------------------------------------------------------------------------------------

  import { Styles } from './Styles/Styles_Board_Items_Dashboard.js';

//----------------------------------------------------------------------------------------
  export const Items_Dashboard = ({ Props }) => {
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, memo } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Styles, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const URI = `${Public.ENV.URI}/Shop`;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Data
//----------------------------------------------------------------------------------------
  
  // Data
  const boxes = [{ID: 'Plushie', Name: "Peluches"}, {ID: 'Aura', Name: "Aura"}, {ID: 'Chips', Name: "Chips"}];

  // State
  const [activeIndex, setActiveIndex] = useState(-1);
  const [lastActiveIndex, setLastActiveIndex] = useState(null);
  
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Controller
//----------------------------------------------------------------------------------------

  useEffect(() => {
    const gamepad = Public.Input.Gamepad;
    gamepad.setHandlers({
      onRight: () => {
        Public.Scripts.Audio.Sound({ID: "Move"});
        setLastActiveIndex(null);        
        setActiveIndex(prevIndex => Math.min(prevIndex + 1, boxes.length - 1)) 
      },
      onLeft: () => {
        Public.Scripts.Audio.Sound({ID: "Move"});
        setLastActiveIndex(null);
        setActiveIndex(prevIndex => Math.max(prevIndex - 1, 0))
      }
    });
    return () => { gamepad.cleanup(); };
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Functions
//----------------------------------------------------------------------------------------

  const Select = (index) => {
    if(lastActiveIndex !== null) {
      setLastActiveIndex(null);
      return;
    }
    setActiveIndex(index);
  };

  const Unselect = () => {
    setLastActiveIndex(activeIndex);
    setActiveIndex(null);
  };

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
return (html`
<${Styled}>
<div className="Board_Items_Dashboard">

  <div className="Boxes">
    
  ${boxes.map((item, index) => {

      const isActive = index === activeIndex;
      const isLastActive = index === lastActiveIndex && activeIndex !== lastActiveIndex;
      const boxClasses = `Box ${isActive ? 'Active' : ''} ${isLastActive ? 'Inactive' : ''}`;
      
      return html`
        <div
          className=${boxClasses}
          onMouseEnter=${() => Select(index)}
          onMouseLeave=${Unselect}
          key=${`Items_Dashboard_${index}`}
        >
          <${RouterDOM.Link} to=${`/Items/${item.ID}`}>
            <div className="Halo"></div>
            <div className="Image"><img src=${`${URI}/Dashboard/Media/${item.ID}.png`} alt=${item.ID}/></div>
            <h2>${item.Name}</h2>
          </${RouterDOM.Link}>
        </div>
      `;
  })}

  </div>
</div>
<//>
`);
}