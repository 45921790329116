//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_Menu.js';

//----------------------------------------------------------------------------------------
  export const App_Menu = ({ Props }) => {
//----------------------------------------------------------------------------------------

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useEffect } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const location = RouterDOM.useLocation(); // Añadimos useLocation

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  let URI_SRC = `${Public.ENV.URI}/Sidebar/Menu/Media`;

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Functions
//----------------------------------------------------------------------------------------

  const changeMenu = (name) => {
    Props.Section.set(name);
    Props.Modal.set({
      type: "",
      options: {}
    });
    Public.Scripts.Audio.Sound({ID: 'Click'});
  };

  const handleHalo = (e, action) => {
    const li = e.currentTarget;
    const icon = li.querySelector('._Icon');
    let halo = li.querySelector('._Halo');
    
    if (action === 'create' && !halo) {
      halo = document.createElement('div');
      halo.className = '_Halo';
      halo.style.cssText = `
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${icon.offsetWidth * 1.1}px;
        height: ${icon.offsetHeight * 1.1}px;
        background-image: url(${icon.src});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        filter: blur(7px);
        opacity: 0;
        transition: opacity 0.2s ease;
        pointer-events: none;
        z-index: 0;
      `;
      li.insertBefore(halo, icon);
      halo.offsetHeight;
      halo.style.opacity = '0.42';
    } 
    else if (action === 'remove' && halo && !li.classList.contains('Active')) {
      halo.style.opacity = '0';
      setTimeout(() => {
        if (halo && halo.parentNode === li) {
          li.removeChild(halo);
        }
      }, 200);
    }
  };

  // Función para extraer la sección actual de la URL
  const getCurrentSection = (pathname) => {
    const path = pathname.split('/')[1];
    if (!path) return 'aura';
    return path;
  };

  // Nueva función para actualizar el menú basado en la ruta actual
  const updateActiveMenuItem = () => {
    const currentSection = getCurrentSection(location.pathname);
    
    // Actualizar Props.Section si es necesario
    Props.Section.set(currentSection);

    // Actualizar halos
    document.querySelector('#App_Menu').querySelectorAll('li').forEach(li => {
      const shouldBeActive = li.closest('a')?.getAttribute('href')?.includes(currentSection);
      if (shouldBeActive) {
        li.classList.add('Active');
        handleHalo({ currentTarget: li }, 'create');
      } else {
        // li.classList.remove('Active');
        handleHalo({ currentTarget: li }, 'remove');
      }
    });
  };

  // Efecto para manejar cambios de ruta

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

function RenderSidebar() {
  return html`
  <div id="App_Menu">
    <${RouterDOM.Link} to="/aura" key="Menu_Aura">
      <li 
        onMouseEnter=${(e) => handleHalo(e, 'create')}
        onMouseLeave=${(e) => handleHalo(e, 'remove')}
        onClick=${() => changeMenu("aura")}
        className=${location.pathname === '/' || location.pathname.startsWith('/aura') ? "Active" : ""}>
        <img src="${URI_SRC}/Aura.png" className="_Icon"/>
      </li>
    <//>

    <${RouterDOM.Link} to="/start" key="Menu_Manual">
      <li 
        onMouseEnter=${(e) => handleHalo(e, 'create')}
        onMouseLeave=${(e) => handleHalo(e, 'remove')}
        onClick=${() => changeMenu("start")}
        className=${location.pathname.startsWith('/start') ? "Active" : ""}>
        <img src="${URI_SRC}/Manual.png" className="_Icon"/>
      </li>
    <//>

    <${RouterDOM.Link} to="/maia" key="Menu_Maia">
      <li 
        onMouseEnter=${(e) => handleHalo(e, 'create')}
        onMouseLeave=${(e) => handleHalo(e, 'remove')}
        onClick=${() => changeMenu("maia")}
        className=${location.pathname.startsWith('/maia') ? "Active" : ""}>
        <img src="${URI_SRC}/Gamepad.png" className="_Icon"/>
      </li>
    <//>

    <${RouterDOM.Link} to="/user" key="Menu_User">
      <li 
        onMouseEnter=${(e) => handleHalo(e, 'create')}
        onMouseLeave=${(e) => handleHalo(e, 'remove')}
        onClick=${() => changeMenu("user")}
        className=${location.pathname.startsWith('/user') 
          || location.pathname.startsWith('/login')
          || location.pathname.startsWith('/sign')
          || location.pathname.startsWith('/restore')
          || location.pathname.startsWith('/verify')
          ? "Active" : ""}>
        <img src="${URI_SRC}/User.png" className="_Icon"/>
      </li>
    <//>

    ${Public.TOKEN.Power === 'ADMIN' && html`
      <${RouterDOM.Link} to="/digitori" key="Menu_Egg">
        <li 
          onMouseEnter=${(e) => handleHalo(e, 'create')}
          onMouseLeave=${(e) => handleHalo(e, 'remove')}
          onClick=${() => changeMenu("digitori")}
          className=${location.pathname.startsWith('/digitori') ? "Active" : ""}>
          <img src="${URI_SRC}/Digitori.png" className="_Icon"/>
        </li>
      <//>

      <${RouterDOM.Link} to="/admin" key="Menu_Admin">
        <li 
          onMouseEnter=${(e) => handleHalo(e, 'create')}
          onMouseLeave=${(e) => handleHalo(e, 'remove')}
          onClick=${() => changeMenu("admin")}
          className=${location.pathname.startsWith('/admin') ? "Active" : ""}>
          <img src="${URI_SRC}/Admin.png" className="_Icon"/>
        </li>
      <//>
    `}
  </div>
  `;
}

//----------------------------------------------------------------------------------------

return html`
  <${Styled}>
    ${RenderSidebar()}
  <//>
`;

//----------------------------------------------------------------------------------------
}