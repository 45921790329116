//----------------------------------------------------------------------------------------

  import { Style } from './Style/Style_App_Aura.js';

//----------------------------------------------------------------------------------------
  export const App_Aura = ({ Props }) => {
//----------------------------------------------------------------------------------------

  let URI = `${Public.ENV.URI}/Aura`;
  const { useRef, useEffect, useState } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const theMain = useRef(null);
  const theText = useRef(null);
  const loadingIndicator = useRef(null);
  const moodImage = useRef(null);
  const textAreaRef = useRef(null);
  const fullMessages = useRef([]);
  const sentMessages = useRef([]);

  // Images
  const [ImagesLoaded, SetImagesLoaded] = useState(0);
  const handleLoad = () => { 
    if(ImagesLoaded >= 4) { return; }
    SetImagesLoaded(prev => prev + 1) 
  };

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => {

    if(ImagesLoaded < 4) { return; }

    // Messages
    const defaultMessage = { 
      role: 'assistant', 
      content: { Messages: [
        { Mood: 'Happy', Msg: '¡Hola! ¡Soy Aura!' },
        { Mood: 'Default', Msg: '¿Qué quieres saber?' },
      ], 
      Responses: [
        // "¿Qué eres?",
        // "¿Dónde estoy?"
      ] 
    } };
    sentMessages.current = [defaultMessage];
    typeText(defaultMessage);

    // Textarea
    if (textAreaRef.current) { textAreaRef.current.focus(); }

  }, [ImagesLoaded]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Set_Loading = (isLoading) => {
    
    if (isLoading) {

      loadingIndicator.current.style.display = 'block';
      theText.current.innerHTML = '';

      const image = document.querySelector('._Centella');
      if (image) { image.classList.add('_Animation_Glow'); }
    
    } else {

      loadingIndicator.current.style.display = 'none';

      const image = document.querySelector('._Centella');
      if (image) { image.classList.remove('_Animation_Glow'); }

    }
  };

  const Aura_Chat = () => {

    textAreaRef.current.value = '';

    Set_Loading(true);
    let formData = new FormData();
    formData.append('Messages', JSON.stringify([...fullMessages.current, ...sentMessages.current]));

    fetch(`${Public.ENV.URL}/apps/aura/chat`, {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then((Response) => {

      if(Response.Result === 'ERROR') { 
        Set_Loading(false);
        return; 
      }

      console.log('Response received:', Response);
      fullMessages.current = Response.Messages;
      sentMessages.current = [];
      let Last_Message = Response.Messages[Response.Messages.length-1];

      typeText(Last_Message);
      Set_Loading(false); 
      
    })
    .catch((error) => {
      console.error('Error:', error);
      Set_Loading(false);
    })
    .finally(() => {
      if (textAreaRef.current) {
        // textAreaRef.current.value = '';
      }
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e.target.value.trim());
    }
  };

  const sendMessage = (messageContent) => {
    const newMessage = { role: 'user', content: messageContent };
    sentMessages.current = [...sentMessages.current, newMessage];
    Aura_Chat();  // Llama directamente para procesar el mensaje
  };

  const handleResponseClick = (response) => {
    sendMessage(response);
  };

  async function typeNextPart (parts, index, showResponses) {
    
    if (index < parts.length) {

      let part = parts[index];
  
      // Añadir un div con clase "_Message Loading"
      const loadingMessageElement = document.createElement('div');
      loadingMessageElement.className = '_Message Loading';
      theText.current.appendChild(loadingMessageElement);
  
      if (part.Mood) {
        Set_Mood(part.Mood);
      }
  
      // Remover la clase "Loading" y añadir el mensaje real
      loadingMessageElement.classList.remove('Loading');
  
      // Detectar y manejar bloques de código
      const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
      let match;
      let lastIndex = 0;
      const fragment = document.createDocumentFragment();
  
      while ((match = codeBlockRegex.exec(part.Msg)) !== null) {
        if (match.index > lastIndex) {
          const textPart = part.Msg.slice(lastIndex, match.index);
          const textElement = document.createElement('span');
          textElement.textContent = textPart;
          fragment.appendChild(textElement);
        }
  
        const codeElement = document.createElement('pre');
        let language = "";
        if (match[1] === 'js') {
          language = "javascript";
        }
        codeElement.innerHTML = `<code class="language-${language || 'plaintext'}">${match[2]}</code>`;
        fragment.appendChild(codeElement);
        // Public.Libs.hljs.highlightElement(codeElement);
  
        lastIndex = codeBlockRegex.lastIndex;
      }
  
      if (lastIndex < part.Msg.length) {
        const textPart = part.Msg.slice(lastIndex);
        const textElement = document.createElement('span');
        textElement.textContent = textPart;
        fragment.appendChild(textElement);
      }
  
      // Añadir el evento de clic para copiar al portapapeles
      loadingMessageElement.appendChild(fragment);
      loadingMessageElement.onclick = () => copyToClipboard(part.Msg);
      Public.Libs.hljs.highlightAll();
  
      // Reproduce el sonido para cada frase y espera a que termine
      await Public.Scripts.Audio.Sound({ ID: 'Boing' });
  
      // Espera un tiempo después de cada mensaje
      let Wait = part.Msg.length * 50;
      if(part.Type === 'Code') { Wait = Wait / 10; }
      await new Promise(resolve => setTimeout(resolve, Wait));
  
      setTimeout(() => {
        typeNextPart(parts, index + 1, showResponses);
      }, 0);
            
    } else {
      showResponses();
    }

  }

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }
  
  function typeText(message) {
    
    theText.current.innerHTML = '';
    let content;
  
    if (typeof message.content === 'string') {
      console.log(message.content);
      content = JSON.parse(message.content);
    } else {
      content = message.content;
    }
  
    const parts = content.Messages;
    const responses = content.Responses;
  
    function showResponses() {
      if (!responses || responses.length <= 0) { return; }
      responses.forEach(response => {
        const responseElement = document.createElement('div');
        responseElement.className = '_Response';
        responseElement.textContent = response;
        responseElement.onclick = () => handleResponseClick(response);
        theText.current.appendChild(responseElement);
      });
      setTimeout(() => { Set_Action("(:Mood:Default:)"); }, 3000);
    }
  
    // El primer mensaje no tiene retraso
    typeNextPart(parts, 0, showResponses);
    
  }
  

  function Set_Action(action) {
    // Convertir el action en un array de argumentos
    const args = action.slice(2, -1).split(':');
    const actionType = args[0];
    const actionValue = args[1];

    switch (actionType) {
      case 'Mood':
        Set_Mood(actionValue);
        break;
      case 'Sound':
        Play_Sound(actionValue);
        break;
      // Agregar más casos según sea necesario
      default:
        console.log(`Unknown action type: ${actionType}`);
    }
  }

  function Set_Mood(mood) {
    if (moodImage.current) {
      moodImage.current.src = `${URI}/Media/Face/${mood}.png`;
    }
  }

  function Play_Sound(sound) {
    const audio = new Audio(`${URI}/Media/Sound/${sound}.wav`);
    audio.play();
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

  return (html`
    <${Styled}>
      <div className="App_Aura" ref=${theMain}>
        <div className="_Top">

          <div className="_Text" ref=${theText}>
            <div className="_Loading" ref=${loadingIndicator}></div>
          </div>

          <div className="_Aura ${ImagesLoaded >= 4 && '_Loaded'}">
            <div className="_Cubit">
              <img src=${`${URI}/Media/Body.png`} className="_Body" onLoad=${handleLoad} content="noindex, nofollow"/>
              <div className="_Face">
                <img ref=${moodImage} src=${`${URI}/Media/Face/Default.png`} onLoad=${handleLoad} content="noindex, nofollow"/>
              </div>
              <img src=${`${URI}/Media/Centella.png`} className="_Centella" onLoad=${handleLoad} content="noindex, nofollow"/>
            </div>
            <div className="_Shadow">
              <img src=${`${URI}/Media/Sombra.png`} onLoad=${handleLoad} content="noindex, nofollow"/>
            </div>
          </div>        

        </div>

        <div className="_Bottom">

          <textarea ref=${textAreaRef} onKeyDown=${handleKeyDown} />
          <div className="_Button_Send" onClick=${() => {
            sendMessage(textAreaRef.current.value.trim());
          }}><i className="icofont-paper-plane icofont-1x"/></div>

        </div>

      </div>
    <//>
  `);
};
