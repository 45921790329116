import { Style } from './Style/Style_App_Aura.js';

//----------------------------------------------------------------------------------------
  export const App_Aura = ({ Props }) => {
//----------------------------------------------------------------------------------------

  let URI = `${Public.ENV.URL}/Apps/Aura`;
  const { useRef, useEffect } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const theMain = useRef(null);
  const theText = useRef(null);
  const loadingIndicator = useRef(null);
  const moodImage = useRef(null);
  const textAreaRef = useRef(null);
  const fullMessages = useRef([]);
  const sentMessages = useRef([]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => {
    const defaultMessage = { role: 'assistant', content: '¡Hola! ¡Soy Aura!' };
    sentMessages.current = [defaultMessage];
    typeText(defaultMessage);
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const Set_Loading = (isLoading) => {
    
    if (isLoading) {

      loadingIndicator.current.style.display = 'block';
      theText.current.innerHTML = '';

      const image = document.querySelector('._Centella');
      if (image) { image.classList.add('_Animation_Glow'); }
    
    } else {

      loadingIndicator.current.style.display = 'none';

      const image = document.querySelector('._Centella');
      if (image) { image.classList.remove('_Animation_Glow'); }

    }
  };

  const Aura_Chat = () => {

    Set_Loading(true);
    let formData = new FormData();
    formData.append('Messages', JSON.stringify([...fullMessages.current, ...sentMessages.current]));

    fetch(`${Public.ENV.URL}/aura/chat`, {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then((Response) => {

      if(Response.Result === 'ERROR') { 
        Set_Loading(false);
        return; 
      }

      console.log('Response received:', Response);
      fullMessages.current = Response.Output.Messages;
      sentMessages.current = [];
      let Last_Message = Response.Output.Messages[Response.Output.Messages.length-1];
      console.log(Last_Message)
      typeText(Last_Message);
      Set_Loading(false); 
      
    })
    .catch((error) => {
      console.error('Error:', error);
      Set_Loading(false);
    })
    .finally(() => {
      if (textAreaRef.current) {
        textAreaRef.current.value = '';
      }
    });
  };

  // Evento de teclado para añadir mensajes
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const newMessage = { role: 'user', content: e.target.value.trim() };
      sentMessages.current = [...sentMessages.current, newMessage];
      Aura_Chat();  // Llama directamente para procesar el mensaje
    }
  };

  function typeText(message) {
    theText.current.innerHTML = '';
    const regex = /```(\w+)([\s\S]*?)```/g;
    const parts = [];
    let lastIndex = 0;

    let match;
    while ((match = regex.exec(message.content)) !== null) {
      parts.push(message.content.slice(lastIndex, match.index));
      parts.push({ language: match[1], code: match[2] });
      lastIndex = regex.lastIndex;
    }
    parts.push(message.content.slice(lastIndex));

    function typeNextPart(index) {

      if (index < parts.length) {

        let part = parts[index];
        
        if (typeof part === 'object') { // Esta parte es código
          console.log(`Code (${part.language}): ${part.code}`);
          const codeElement = document.createElement('pre');
          codeElement.innerHTML = `<code class="language-${part.language}">${part.code}</code>`;
          Public.Libs.hljs.highlightElement(codeElement);
          theText.current.appendChild(codeElement);
          setTimeout(() => typeNextPart(index + 1), 100);
        } else { // Esta parte es texto normal
          const words = part.split(' ');
          let wordIndex = 0;

          function typeNextWord() {
            if (wordIndex < words.length) {
              let word = words[wordIndex++];
              if (/\(:[^)]+\)/.test(word)) {
                const action = word.match(/\(:[^)]+\)/)[0];
                console.log(`Executing action: ${action}`);
                Set_Action(action);
                setTimeout(typeNextWord, 100);
              } else {
                theText.current.innerHTML += word + ' ';
                let delay = 100 + word.length * 10;
                setTimeout(typeNextWord, delay);
              }
            } else {
              setTimeout(() => typeNextPart(index + 1), 100);
            }
          }

          typeNextWord();
        }

      } else {
        // Restablecer Mood
        setTimeout(() => { Set_Action("(:Mood:Default:)"); }, 3000);
      }
      
    }
  
    typeNextPart(0);

  }

  function Set_Action(action) {
    // Convertir el action en un array de argumentos
    const args = action.slice(2, -1).split(':');
    const actionType = args[0];
    const actionValue = args[1];

    switch (actionType) {
      case 'Mood':
        Set_Mood(actionValue);
        break;
      case 'Sound':
        Play_Sound(actionValue);
        break;
      // Agregar más casos según sea necesario
      default:
        console.log(`Unknown action type: ${actionType}`);
    }
  }

  function Set_Mood(mood) {
    if (moodImage.current) {
      moodImage.current.src = `${URI}/Media/Face/${mood}.png`;
    }
  }

  function Play_Sound(sound) {
    const audio = new Audio(`${URI}/Media/Sound/${sound}.wav`);
    audio.play();
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

  return (html`
    <${Styled}>
      <div className="App_Aura" ref=${theMain}>
        <div className="_Top">
          <!--<pre><code class="language-javascript">console.log("a");</code></pre>-->
          <div className="_Text" ref=${theText}>
            <div className="_Loading" ref=${loadingIndicator}></div>
          </div>
          <div className="_Aura">
            <div className="_Cubit">
              <img src="${URI}/Media/Body.png" className="_Body"/>
              <div className="_Face"><img ref=${moodImage} src="${URI}/Media/Face/Default.png" /></div>
              <img src="${URI}/Media/Centella.png" className="_Centella"/>
            </div>
            <img src="${URI}/Media/Sombra.png" className="_Shadow"/>
          </div>
        </div>
        <div className="_Bottom">
          <textarea ref=${textAreaRef} onKeyDown=${handleKeyDown} />
        </div>
      </div>
    <//>
  `);
};
