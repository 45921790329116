//----------------------------------------------------------------------------------------

  import { Audio } from './Audio/Audio.js';
  import { Notify } from "./Notify/Notify.js";
  import { Validation } from "./Validation/Validation.js";

  import { Public_Scripts_Animation } from "./Animation/Public_Scripts_Animation.js";
  import { Public_Scripts_Vectorial } from "./Vectorial/Public_Scripts_Vectorial.js";

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class Public_Scripts { constructor (Public) {
//----------------------------------------------------------------------------------------

  this.Public = Public;

  this.Audio = new Audio();
  this.Notify = new Notify();

  this.Animation = new Public_Scripts_Animation();
  this.Vectorial = new Public_Scripts_Vectorial();

  this.Validation = new Validation();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Toggle_Fullscreen = () => {
//----------------------------------------------------------------------------------------

  // if (!Node_DOM) { }
  let Node_DOM = document.getElementById('App'); 

  const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
  const requestFullscreen = Node_DOM.requestFullscreen || Node_DOM.webkitRequestFullscreen || Node_DOM.msRequestFullscreen;
  const exitFullscreen = document.exitFullscreen || document.webkitExitFullscreen || document.msExitFullscreen;

  if (isFullscreen) { 
    document.querySelector("#Board_Sidebar").style.display = "flex";
    exitFullscreen.call(document); 
  } 
  else { 
    // document.querySelector("#Board_Sidebar").style.display = "none";
    requestFullscreen.call(Node_DOM); 
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  async Route ({Route, Form, Callback}) {
//----------------------------------------------------------------------------------------
    // Event.preventDefault();
    fetch(`${this.Public.ENV.URL}/${Route}`, { 
        method: 'POST', 
        body: Form
    })
    .then((response) => { return response.json(); })
    .then(res => { Callback(res) });

  }
  
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Blob_To_String (blob) {
//----------------------------------------------------------------------------------------  

  return string;

}
//----------------------------------------------------------------------------------------
  StringToBlob(file) {
//----------------------------------------------------------------------------------------  

  // Extraer el tipo MIME y la parte base64 del Data URL
  const mimePattern = /^data:(.*?);base64,/;
  const mimeMatch = file.match(mimePattern);
  if (!mimeMatch) {
      throw new Error("Invalid Data URL");
  }
  const mimeType = mimeMatch[1];
  const base64String = file.replace(mimePattern, '');

  // Decodificar la cadena base64
  const decodedBase64 = atob(base64String);
  const arrayBuffer = new ArrayBuffer(decodedBase64.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < decodedBase64.length; i++) {
      uint8Array[i] = decodedBase64.charCodeAt(i);
  }

  // Crear un blob a partir del array buffer
  const blob = new Blob([arrayBuffer], { type: mimeType });
  return blob;
    
}
//----------------------------------------------------------------------------------------
  Blob_To_Img_URL (blob) {
//----------------------------------------------------------------------------------------  

  if(!blob) { return;}
  
  if (blob instanceof Blob === false) {
    console.error("Passed arg must be a Blob", blob);
    return;
  }

  var urlCreator = window.URL || window.webkitURL;
  var imageUrl = urlCreator.createObjectURL(blob);
  return imageUrl;

}
//----------------------------------------------------------------------------------------
  async File_To_Base64 (file) {
//----------------------------------------------------------------------------------------
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
// 👇 Choose
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Choose_Panel = async (Panel_ID) => {
//----------------------------------------------------------------------------------------
  return (async () => {
    const {default: Panel} = await import(
    `${this.Public.ENV.URI}/Maia/Core/TREE/PANEL/Index/${Panel_ID}/Panel_${Panel_ID}.js`
    );
    return Panel;
  })();

}
//----------------------------------------------------------------------------------------
  Choose_Part = (Part_ID) => {
//----------------------------------------------------------------------------------------
  
  return this.Public.Libs.React.lazy(() => import(
    `${this.Public.ENV.URL}/Public/Parts/${Part_ID}/Part_${Part_ID}.js`
  ));

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
// Dynamic Script Tags
//----------------------------------------------------------------------------------------

  Add_ScriptTag ({ID, URI, Callback = () => {}}) {

    let src = `${URI}`;
    // console.log("Loading", src);
    var scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', src); 
    scriptElement.setAttribute('data-script', ID);
    scriptElement.onload = Callback;
    document.head.appendChild(scriptElement);

  }

  Remove_ScriptTag ({ID, Callback = () => {}}) {

    let select = document.head.querySelector(`script[data-script="${ID}"]`);
    if(select) {
      console.log("Remove <Script> Tag...", ID);
      document.head.removeChild(select);
      Callback();
    }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
// Styling
//----------------------------------------------------------------------------------------

  Blur_Background (PanelRef) {

    let Canvas = this.MAIA.TREE.RENDER.Canvas;
    var canvas = document.createElement("canvas");
    
    canvas.width = PanelRef.offsetWidth;
    canvas.height = PanelRef.offsetHeight;

    let offsetTop = PanelRef.offsetTop;
    let offsetLeft = PanelRef.offsetLeft;

    canvas.getContext('2d').filter = "blur(20px)";
    canvas.getContext('2d').drawImage(
    Canvas.Ref, offsetLeft, offsetTop, Canvas.Ref.offsetWidth, Canvas.Ref.offsetHeight, 0, 0, Canvas.Ref.offsetWidth, Canvas.Ref.offsetHeight);

    canvas.style.zIndex = -1;
    canvas.style.position = "absolute";
    canvas.style.top = 0;
    canvas.style.left = 0;
    
    PanelRef.appendChild(canvas);

  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
// Panel API
//----------------------------------------------------------------------------------------

  Panel_Remove (ID) {
    alert("Deprecated?")
    this.PANEL.Delete(ID);
  }

  Close (ID) {
    alert("Deprecated")
    this.MAIA.TREE.PANEL.Delete(ID);
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
// Sound API
//----------------------------------------------------------------------------------------

  Stop_Music () { 
    this.MAIA.TREE.SOUND.Stop(); 
  }

//----------------------------------------------------------------------------------------
// Client API
//----------------------------------------------------------------------------------------

  Refresh_Screen () { 
    this.MAIA.TREE.CLIENT.DOM.Refresh_Screen();
  }
  
//----------------------------------------------------------------------------------------
}